<template>
  <ul class="shareBox">
    <li
      v-for="item in items"
      :key="item.id"
      class="item"
      @click="doSomething(item)"
    ><i
        :class="item.icon"
        class="base-iconfont"
      ></i>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    doSomething(item) {
      if (item.flag === '1') {
        window.open(item.link, '_blank');
        return;
      }
      this.$emit('onOpenMa', item.imgUrl);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/ShuJiuXiang/theme.scss';
.shareBox {
  display: flex;
  align-items: center;
  .item {
    width: 48px;
    height: 48px;
    border: 1px solid $border-color-1;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: $icon-color-1;
    @media screen and (min-width: $hover-point) {
      transition: 300ms;
      &:hover {
        background: $major-color;
        color: #fff;
        border-color: $major-color;
      }
    }
    + .item {
      margin-left: 20px;
    }
    ::v-deep .base-iconfont {
      font-size: 24px;
    }
  }
}
</style>
