<template>
  <el-backtop
    class="sjx-backtop"
    v-bind="$attrs"
  >
    <img
      src="@/assets/ShuJiuXiang/backtop@2x.png"
      alt=""
      class="icon"
    />
  </el-backtop>
</template>

<script>
export default {
  inheritAttrs: false,
};
</script>

<style lang="scss" scoped>
@import '@/styles/ShuJiuXiang/theme.scss';

.sjx-backtop {
  transition: opacity 0.3s;
  &::v-deep.el-backtop {
    width: 50px;
    height: 50px;
    background: $major-color;
    border-radius: 0;
    box-shadow: none;
    z-index: 99;
  }
  .icon {
    width: 20px;
    height: 20px;
  }
  @media screen and (min-width: $hover-point) {
    &:hover {
      opacity: 0.8;
    }
  }
}
</style>
