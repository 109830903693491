<template>
  <el-dialog
    width="292px"
    class="telephoneModal"
    v-bind="this.$attrs"
    title=""
    :visible.sync="visible"
    :before-close="onClose"
    :modal-append-to-body="false"
  >

    <div class="base-icon-dianhua1 base-iconfont"></div>
    <div
      v-for="item in listData"
      :key="item.telId"
      class="telItem"
    ><a
        :href="`tel:${item.telPhone}`"
        class="no-gesture"
      >{{ item.telName }}：{{ item.telPhone }}</a></div>
  </el-dialog>
</template>

<script>
import { getTel } from '@/api/ShuJiuXiang/join';
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    onClose: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      listData: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      const res = await getTel();
      this.listData = res.filter((d) => d.telType === 'phone');
    },
  },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';
@import '@/styles/ShuJiuXiang/theme.scss';
.telephoneModal {
  ::v-deep .el-dialog {
    border-radius: 0;
    overflow: hidden;
    .el-dialog__header {
      padding: 0;
    }
    .el-dialog__title {
      display: none;
    }
    .el-dialog__headerbtn {
      line-height: 0;
      font-size: 22px;
      top: 18px;
      right: 14px;
      color: rgba(0, 0, 0, 0.5);
      &:focus,
      &:hover .el-dialog__close {
        color: $sub-color-2;
      }
    }
    .el-dialog__title {
      font-size: 14px;
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-right: 10px;
    }
    .el-dialog__body {
      text-align: center;
      color: $sub-color-2;
      padding: 56px 30px;
      a {
        color: $sub-color-2;
      }
    }
  }
  .base-iconfont {
    font-size: 32px;
    line-height: 1;
  }
}
.telItem {
  font-size: 16px;
  line-height: math.div(20, 16);
  margin-top: 24px;
}
</style>