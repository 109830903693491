import request from '@/utils/request';

const PATH = '/v1/web';

// 1.网站基本信息
export function getBaseInfo(params = {}) {
  return request({
    url: PATH + '/info',
    method: 'GET',
    params,
  });
}

// 获取微信、抖音二维码
export function getTalk(params = {}) {
  return request({
    url: PATH + '/talk',
    method: 'GET',
    params,
  });
}
