<template>
  <div
    class="header"
    ref="header"
  >
    <router-link to="/sjx/home"><img
        alt="logo"
        :src="logoUrl || globalData.webLogo"
        class="logo"
      /></router-link>
    <SJXBtn
      @click="toggleStatus"
      :class="{ bMenuBox: isBlack }"
      :active="active"
    />
    <transition name="menu">
      <MenuBox
        v-if="visible"
        @onClose="toggleStatus"
      />
    </transition>
  </div>
</template>

<script>
import { getScrollTop } from '@/utils/common';
import { mapState } from 'vuex';
import _ from 'lodash';
import { SJXBtn } from '@/components/MenuButton';
import Menu from './Menu.vue';

export default {
  components: {
    SJXBtn,
    MenuBox: Menu,
  },
  mounted() {
    this.reset();
    this.onScroll();
    document.addEventListener('scroll', _.debounce(this.onScroll, 50));
  },
  data() {
    return {
      logoUrl: '', // logo颜色
      isBlack: false, // menu按钮颜色
      active: true, // menu按钮打开状态
      visible: false, // 是否显示导航
      initBlack: false,
    };
  },
  computed: {
    ...mapState({
      isMobile: 'isMobile',
      visibleWH: 'visibleWH',
      hearderScrollMarks: 'hearderScrollMarks',
      globalData: (state) => state.sjx.globalData,
    }),
  },
  methods: {
    reset() {
      this.isBlack = this.isMobile;
      this.logoUrl = this.isMobile ? this.globalData.webLogoBlack : this.globalData.webLogo;
      console.log('this.logoUrl: ', this.logoUrl);
      this.active = !this.isMobile;
      const st = getScrollTop();
      this.visible = st < 1 && !this.isMobile;
    },
    onScroll() {
      if (this.isMobile) return;
      const st = getScrollTop();
      const hh = this.$refs.header?.clientHeight;
      const { height, isBlack } = this.hearderScrollMarks[this.$route.name] || {};
      const mark = height === undefined ? this.visibleWH.height : height;
      this.logoUrl = st > mark - hh ? this.globalData.webLogoBlack : this.globalData.webLogo;
      this.isBlack = isBlack || st > mark - hh;
      this.visible = st < 1;
      this.active = st < 1;
    },
    toggleStatus() {
      this.active = !this.active;
      this.visible = !this.visible;
    },
  },
  destroyed() {
    document.removeEventListener('scroll', this.onScroll);
  },
  watch: {
    globalData() {
      this.reset();
    },
    isMobile() {
      this.reset();
    },
    $route() {
      this.reset();
      this.onScroll();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/ShuJiuXiang/theme.scss';
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  padding: 40px 74px 0 38px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .logo {
    width: 134px;
    height: 35px;
    display: block;
  }
  .bMenuBox {
    > ::v-deep span {
      background: $border-color-2;
    }
  }
  ::v-deep {
    .MenuBtn.active > span {
      background: #fff;
    }
  }
}
.menu-leave-to,
.menu-enter {
  transform: translateX(100%);
}
.menu-leave-active,
.menu-enter-active {
  transition: 800ms;
}
@media screen and (max-width: $res-point-6) {
  .header {
    background: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    height: 60px;
    padding: 0 5%;
  }
}
</style>