<template>
  <div
    class="MenuBtn"
    :class="{ active }"
    v-on="$listeners"
  >
    <span class="line1"></span>
    <span class="line2"></span>
    <span class="line3"></span>
  </div>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.MenuBtn {
  width: 32px;
  height: 26px;
  position: relative;
  cursor: pointer;
  z-index: 1020;
  > span {
    height: 2px;
    background: #fff;
    position: absolute;
    left: 0;
    transition: 0.8s;
  }
  .line1 {
    width: 100%;
    top: 0;
  }
  .line2 {
    width: 12px;
    top: (26px - 2px) / 2;
  }
  .line3 {
    width: 22px;
    top: 26px - 2px;
  }
  &.active {
    > span {
      width: 100%;
    }
    .line3,
    .line1 {
      top: (26px - 2px) / 2;
    }
    .line2 {
      transform: translateX(100%);
      opacity: 0;
    }
    .line1 {
      transform: rotate(225deg) translateZ(0);
    }
    .line3 {
      transform: rotate(-225deg) translateZ(0);
    }
  }
}
</style>