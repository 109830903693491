<template>
  <div class="sjx-box">
    <div class="bg"></div>
    <PageHeader />
    <router-view />
    <PageFooter>
      <SJXTop :right="20" />
    </PageFooter>
  </div>
</template>

<script>
import Footer from './components/Footer/index.vue';
import Header from './components/Header/index.vue';
import SJXTop from '@/components/BackTop/SJXTop.vue';
import { getBaseInfo, getTalk } from '@/api/ShuJiuXiang/base';

export default {
  components: {
    PageFooter: Footer,
    PageHeader: Header,
    SJXTop,
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      const res = await getBaseInfo();
      const talkRes = await getTalk();
      this.$store.commit('sjx/GLOBAL_DATA', { ...res, talkData: talkRes });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/ShuJiuXiang/theme.scss';
.sjx-box {
  min-height: 100vh;
  color: $text-font-5;
  font-family: Helvetica, 'Source Han Sans CN';
  ::v-deep {
    a {
      color: $text-font-5;
    }
  }
  .bg {
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('../../assets/ShuJiuXiang/page-bg.jpg') no-repeat center;
    background-size: cover;
  }
}
</style>
