<template>
  <el-dialog
    width="320px"
    class="scanMaBox"
    v-bind="this.$attrs"
    :visible.sync="visible"
    :before-close="onCloseMa"
    :modal-append-to-body="false"
  >
    <div class="scanMa">
      <img
        :src="imgUrl"
        alt=""
      />
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    imgUrl: {
      type: String,
      default: '',
    },
    onCloseMa: {
      type: Function,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/ShuJiuXiang/theme.scss';
.scanMa {
  display: flex;
  align-items: center;
  flex-direction: column;
  > img {
    max-width: 100%;
  }
}
.scanMaBox {
  ::v-deep .el-dialog {
    border-radius: 0;
    overflow: hidden;
    .el-dialog__header {
      display: flex;
      padding: 0;
    }
    .el-dialog__title {
      font-size: 14px;
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-right: 10px;
    }
    .el-dialog__headerbtn {
      line-height: 0;
      font-size: 22px;
      top: 18px;
      right: 14px;
      color: rgba(0, 0, 0, 0.5);
      &:focus,
      &:hover .el-dialog__close {
        color: $sub-color-2;
      }
    }
    .el-dialog__body {
      padding: 60px;
    }
  }
}
</style>