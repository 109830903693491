<template>
  <div>
    <div class="footer">
      <div class="sjx-container">
        <slot></slot>
        <div class="main">
          <router-link to="/sjx/home">
            <img
              :src="globalData.webLogoBlack"
              :alt="globalData.webName"
              class="logo"
            />
          </router-link>
          <div class="center">
            <div class="base-join-info">
              <p>加盟热线：<a
                  :href="`tel:${globalData.webJoinTel}`"
                  class="no-gesture"
                >{{ globalData.webJoinTel }}</a>
              </p>
              <p>联系地址：<a
                  href="https://surl.amap.com/6c94GNaRg5m"
                  target="_blank"
                >{{ globalData.webAddress }}</a></p>
            </div>
            <div class="icp-info">
              <a
                href="https://beian.miit.gov.cn/"
                target="_blank"
              >{{ globalData.webCopyright }}</a>
              <a
                href="https://zeaylu.com/"
                target="_blank"
                class="zlsj"
              >网站设计：子鹿设计</a>
            </div>
          </div>
          <SJXShare
            @onOpenMa="onOpenMa"
            :items="shareData"
          />
        </div>
      </div>
    </div>
    <ScanMaModal
      :visible="visible"
      :imgUrl="maUrl"
      :onCloseMa="onCloseMa"
    />
  </div>
</template>

<script>
import { SJXShare, ScanMaModal } from '@/components/Share';
import { mapState } from 'vuex';

export default {
  components: {
    SJXShare,
    ScanMaModal,
  },
  data() {
    return {
      visible: false,
      maUrl: '',
    };
  },
  methods: {
    onOpenMa(imgUrl) {
      this.maUrl = imgUrl;
      this.visible = true;
    },
    onCloseMa() {
      this.visible = false;
      this.maUrl = '';
    },
  },
  computed: {
    ...mapState({
      globalData: (state) => state.sjx.globalData,
    }),
    shareData() {
      const { talkData = [] } = this.globalData;
      return talkData.map((d) => {
        return {
          id: d.talkId,
          icon: d.talkImage,
          flag: d.talkFlag,
          imgUrl: d.talkQr,
          link: d.talkUrl,
        };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/ShuJiuXiang/theme.scss';
.footer {
  padding: 100px 0 135px;
  background: #fff;
  .main {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .logo {
    width: 146px;
    height: 38px;
  }
  .icp-info {
    margin-top: 35px;
  }
  a:not(.no-gesture) {
    @media screen and (min-width: $hover-point) {
      &:hover {
        color: $sub-color-2;
        text-decoration: underline;
      }
    }
  }
}
.zlsj {
  margin-left: 20px;
}
.base-join-info {
  line-height: 2;
}
.icp-info {
  font-size: 12px;
}
@media screen and (max-width: $res-point-3) {
  .footer {
    padding: 100px * 0.7 0 135px * 0.7;
  }
}
@media screen and (max-width: $res-point-7) {
  .footer {
    .main {
      flex-wrap: wrap;
      justify-content: flex-start;
    }
    .logo {
      margin-right: 60px;
    }
    ::v-deep {
      .shareBox {
        order: 1;
      }
    }
    .center {
      order: 2;
      width: 100%;
      padding-left: 60px + 146px;
      margin-top: 20px;
    }
  }
  @media screen and (max-width: $res-point-8) {
    .footer {
      padding: (70 / 640) * 100% 0 (100 / 640) * 100%;
      .logo {
        display: none;
      }
      .center {
        padding-left: 0;
      }
    }
  }
}
</style>
