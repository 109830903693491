<template>
  <div class="MenuBox">
    <div class="nav">
      <MenuItem
        v-for="item in navData"
        :key="item.key"
        :itemData="item"
        class="item"
        @change-nav="changeNav"
        :active="active"
      />
    </div>
    <div class="contacts">
      <span
        class="base-iconfont base-icon-weixin"
        @click="visible = true"
      ></span>
      <span
        @click="telVisible = true"
        class="base-iconfont base-icon-dianhua1"
      ></span>
    </div>
    <ScanMaModal
      :imgUrl="globalData.webWxQr"
      :visible="visible"
      :onCloseMa="() => { visible = false }"
    />
    <TelephoneModal
      :visible="telVisible"
      :onClose="() => { telVisible = false }"
    />
  </div>
</template>

<script>
import { ScanMaModal, TelephoneModal } from '@/components/Share';
import { navList as brandNav } from '@/views/ShuJiuXiang/Brand/dada';
import { navList as newsNav } from '@/views/ShuJiuXiang/News/data';
import { mapState } from 'vuex';
import _ from 'lodash';
import MenuItem from './MenuItem.vue';
const nav = [
  {
    text: '首页',
    key: 'sjxHome',
    url: '/sjx/home',
  },
  {
    text: '品牌中心',
    key: 'sjxBrand',
    url: '/sjx/brand/special',
    children: brandNav,
  },
  {
    text: '新闻资讯',
    key: 'sjxNews',
    url: '/sjx/news/activity',
    children: newsNav,
  },
  {
    text: '关于我们',
    key: 'sjxAbout',
    url: '/sjx/about',
  },
  {
    text: '加盟合作',
    key: 'sjxJoin',
    url: '/sjx/join',
  },
];
export default {
  components: {
    ScanMaModal,
    TelephoneModal,
    MenuItem,
  },
  created() {
    const { type } = this.$route.params;
    const { name } = this.$route;
    this.active = type ? `${name}${_.capitalize(type)}` : name;
  },
  data() {
    return {
      navData: nav, // 导航数据
      active: 'SJXHome', // 一级导航active
      visible: false, // 微信弹窗
      telVisible: false, // 电话弹窗
    };
  },
  methods: {
    changeNav(key, url) {
      this.active = key;
      this.$emit('onClose');
      this.$router.push(url);
    },
  },
  computed: {
    ...mapState({
      globalData: (state) => state.sjx.globalData,
      isMobile: 'isMobile',
    }),
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/ShuJiuXiang/theme.scss';
.MenuBox {
  position: fixed;
  z-index: 1010;
  top: 0;
  bottom: 0;
  right: 0;
  width: 180px;
  background: url('../../../../assets/ShuJiuXiang/menu-bg.jpg') no-repeat;
  background-size: cover;
  color: #fff;
  padding: 100px 0 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .item {
    + .item {
      margin-top: 42px;
    }
  }
}
.contacts {
  padding: 0 25px 0 50px;
  span {
    display: block;
    width: 24px;
    height: 24px;
    font-size: 24px;
    line-height: 1;
    cursor: pointer;
    + span {
      margin-top: 28px;
    }
    @media screen and (min-width: $hover-point) {
      transition: 300ms;
      &:hover {
        color: $active-color-1;
      }
    }
  }
}
@media screen and (max-width: $res-point-9) {
  .MenuBox {
    padding: 80px 0 30px;
    .item {
      + .item {
        margin-top: 22px;
      }
    }
  }
  .contacts {
    span {
      font-size: 20px;
      width: 20px;
      height: 20px;
      + span {
        margin-top: 18px;
      }
    }
  }
}
</style>