<template>
  <div
    class="menuItem"
    :class="{ active: active.includes(itemData.key)  }"
  >
    <span @click="changeNav(itemData.key, itemData.url, $event)">{{ itemData.text }}</span>
    <div
      class="pNav"
      v-if="itemData.children"
    >
      <span
        v-for="item in itemData.children"
        :key="item.key"
        @click="changeTwoNav(item.key, item.url)"
        :class="{ active: item.key === active }"
      >{{ item.text }}</span>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import { mapState } from 'vuex';
export default {
  props: {
    itemData: {
      type: Object,
      default: () => ({}),
    },
    active: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    changeNav(key, url, ev) {
      const $pNav = $(ev.target)?.next();
      const hasNav = $pNav?.hasClass('pNav');
      if (this.isMobile && !this.isOpen && hasNav) {
        $pNav.stop().slideDown();
        this.isOpen = true;
        return;
      }
      this.$emit('change-nav', key, url);
    },
    changeTwoNav(key, url) {
      this.$emit('change-nav', key, url);
    },
  },
  computed: {
    ...mapState(['isMobile']),
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/ShuJiuXiang/theme.scss';
.menuItem {
  span {
    display: block;
    line-height: 14px;
  }

  > span {
    padding: 0 25px 0 50px;
    position: relative;
    font-weight: bold;
    cursor: pointer;
    transition: 300ms;
    &::before {
      content: '';
      background: $sub-color-1;
      width: 6px;
      height: 0;
      bottom: 50%;
      left: 0;
      margin-bottom: -7px;
      position: absolute;
      transition: 300ms linear;
    }
  }
  &.active {
    > span {
      color: $active-color-1;
      &::before {
        bottom: auto;
        margin-bottom: 0;
        height: 14px;
        top: 50%;
        margin-top: -7px;
      }
    }
  }
  @media screen and (min-width: $hover-point) {
    transition: 300ms;
    &:hover {
      color: $active-color-1;
    }
  }
}
.pNav {
  display: none;
  > span {
    display: block;
    padding: 17px 25px 0 60px;
    opacity: 0.8;
    font-size: 13px;
    &.active {
      color: $active-color-1;
    }
  }
}
@media screen and (max-width: $res-point-9) {
  // .menuItem {
  //   + menuItem {
  //     margin-top: 22px;
  //   }
  // }
  .pNav {
    > span {
      padding-top: 12px;
    }
  }
}
</style>