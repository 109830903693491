import request from '@/utils/request';

const PATH = '/v1/join';
const PATH_INDEX = '/v1/index';

// 合作加盟基本信息
export function getBaseInfo(params = {}) {
  return request({
    url: PATH + '/info',
    method: 'GET',
    params,
  });
}

// 加盟优势
export function getMerit(params = {}) {
  return request({
    url: PATH + '/merit',
    method: 'GET',
    params,
  });
}

// 加盟支持
export function getHold(params = {}) {
  return request({
    url: PATH + '/hold',
    method: 'GET',
    params,
  });
}

export function getTel(params = {}) {
  return request({
    url: PATH + '/tel',
    method: 'GET',
    params,
  });
}

// 首页加盟表单板块
export function leaveMsg(params = {}) {
  return request({
    url: PATH_INDEX + '/form',
    method: 'POST',
    params,
  });
}
